import { Injectable } from '@angular/core'
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { catchError, Observable, throwError } from 'rxjs'
import { ToastService } from '../toast/toast.service'
import { AuthService } from '../auth/auth.service'
import { AccountService } from '../account/account.service'
import { Router } from '@angular/router'
import { APP_ROUTES } from '../../common/models/app-routes.model'
import { DateHelper } from '../../common/static/date.helper'

@Injectable()
export class RequestInterceptorService implements HttpInterceptor {

  constructor(
    private router: Router,
    private authService: AuthService,
    private toastService: ToastService,
    private accountService: AccountService
  ) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    request = this.addAuthHeader(request)
    return next.handle(request).pipe(
      catchError(error => {
        return this.handleResponseError(error, request, next)
      })
    )
  }

  private addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    const authHeader: string = this.authService.getSessionKey()
    const currentCompanyId: string | undefined = this.accountService.getCurrentCompanyId()
    if (authHeader && currentCompanyId) {
      return request.clone({
        setHeaders: {
          'Authorization': `Bearer ${ authHeader }`,
          'x-company-id': currentCompanyId,
          'x-user-timezone': DateHelper.getTimezone() || 'Europe/Kiev'
        }
      })
    } else if (authHeader) {
      return request.clone({
        setHeaders: {
          'Authorization': `Bearer ${ authHeader }`,
          'x-user-timezone': DateHelper.getTimezone() || 'Europe/Kiev'
        }
      })
    }
    return request
  }

  private handleResponseError(error: HttpErrorResponse, request?: HttpRequest<any>, next?: any) {
    if (error.status === 401) {
      this.authService.logout()
    } else if (error.status === 403) {
      this.authService.logout()
    } else if (error.status === 404 /*|| error.status === 500*/) {
      /*const url: string = this.router.routerState.snapshot.url
      const substrIndex: number = url.indexOf(APP_ROUTES.LIST)
      if (substrIndex != -1) {
        this.router.navigate([url.substring(0, substrIndex + APP_ROUTES.LIST.length)])
      } else {
        this.router.navigate(['/'])
      }*/
      this.router.navigate(['/'])
    }
    this.toastService.showServerError({
      i18nKey: error?.error?.message || error?.message
    })
    return throwError(error)
  }

}
