import { IBox } from './box.model'
import { IAffiliateModel } from './affiliate.model'

import { ICompanyModel } from './company.model'
import { IVerticalTemplateModel } from './verticalTemplate.model'

import { IConversionHoldModel } from './conversionHold.model'
import { IAccountModel } from './account.model'

import { ISendingScenarioModel } from './injector/scenario.model'
import { IBrandModel } from './brand.model'
import { IBrandIntegrationModel } from './brandIntegration.model'
import { IBrandStatusGroupModel } from './brandStatus.model'
import { IEmailCheckResult, IIPCheckResult, IPhoneCheckResult } from './fraud-check.model'

export enum ELeadStatus {
  New = 'new',
  Sent = 'sent',
  Deposited = 'deposited',
  Failure = 'failure',
}

export enum ELeadRegistrationStatus {
  Sent = 'sent',
  Deposited = 'deposited',
  Failure = 'failure',
}

export enum ELeadSystemSourceType {
  API = 'api',
  Marketplace = 'marketplace',
  Account = 'account'
}

export type LeadProfile = { [key: string]: string | number | boolean };

export interface ILeadSenderUserAgentMeta {
  userAgent: string;
  ip: string;
}

export interface ILeadUserAgentInfo {
  userAgentMeta: ILeadSenderUserAgentMeta,
  ip: string;
}

/* ToDo:
    * Check fingerprint and headless
    * Mark if there are few leads with same fingerprint (add filter by fingerprint)
    *
 */
export interface ILeadAutologinMeta {
  isUsed: boolean;
  isIPMatched: boolean;
  ip: string;
  userAgent: string;
  fingerprint: string;
  headless: boolean;
  isFingerprintUnique: boolean;
}

export interface ILeadFraudCheckMeta {
  phone?: IPhoneCheckResult;
  email?: IEmailCheckResult;
  ip?: IIPCheckResult;
  messengers?: IMessengersCheckResult
}

export interface IMessengersCheckResult {
  status: string
  whatsapp: boolean
  fraudScore: number
}

export interface ILeadFraudCheck {
  isFraudChecked: boolean;
  fraudCheckedAt: Date;
  totalFraudScore: number;
  fraudCheckResult?: ILeadFraudCheckMeta;
}

// ToDo: Move leads to mongodb
export interface ILead extends ILeadUserAgentInfo, ILeadFraudCheck, ILeadAutologinInfo {
  id: string;
  status: ELeadStatus; // Internal system status
  rawStatus: string; // Internal system status
  crmId: string; // Internal system status
  brandStatusGroup?: IBrandStatusGroupModel;
  companyId: string;
  verticalTemplateId: string;
  affiliateId: string;
  isConversionHeld: boolean;
  systemSource: ELeadSystemSourceType; // Where lead came from
  profile: LeadProfile; // Vertical related profile attributes
  funnel: string; // Name of funnel lead came from
  landingURL: string; // Link to the funnel lead came from
  comment?: string;
  geo: string; // Country of lead came from
  country: string; // ToDo: Add to migration
  lang: string; // Lead speaking language
  landingLang: string; // Landing language lead came from
  isDeposited: boolean;
  activeRegistrationId?: string;
  isAttempted: boolean; // TRUE in case we tried to send lead to the brand
  isValid: boolean; // Is lead all attributes are valid
  isTransferred: boolean; // TRUE in case we sent lead to brand CRM
  isInjected: boolean; // TRUE in case we injected lead via system to brand
  isTest: boolean; // TRUE in case lead is just test
  isStatusSyncable: boolean; // We try to sync lead rawStatus with brand CRM when TRUE
  isConversionSyncable: boolean; // We try to sync lead isDeposit status with brand CRM when TRUE
  utmSource: string; // fb, google etc.
  utmMedium: string; // offer type: cpa, cpc, cpl etc.
  utmCampaign: string; // name of campaign
  utmId: string; // id of source base
  subId: string;
  subId_a?: string; // affiliate optional parameter for own usage
  subId_b?: string; // affiliate optional parameter for own usage
  subId_c?: string; // affiliate optional parameter for own usage
  subId_d?: string; // affiliate optional parameter for own usage
  subId_e?: string; // affiliate optional parameter for own usage
  subId_f?: string; // affiliate optional parameter for own usage
  frozenBrandStatus?: string
  depositedAt?: Date;
  injectedAt: Date;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  isBrandStatusAnomalyDetected?: boolean
}

export interface ILeadRegistration {
  id: string;
  isPublic: boolean; // This attribute means should we show this registration for affiliate or not
  leadId: string;
  brandStatusGroupId?: string;
  crmId: string; // Brand CRM ID
  rawStatus?: string; // Brand CRM status
  frozenBrandStatus?: string; // Brand CRM status
  status: ELeadRegistrationStatus; // Internal system status
  companyId: string;
  isConversionHeld: boolean;
  verticalTemplateId: string;
  affiliateId: string;
  brokerId: string;
  boxId: string;
  integrationId: string;
  autologinMeta: ILeadAutologinMeta;
  comment: string;
  isTransferred: boolean; // TRUE in case we sent lead to brand CRM
  statusSyncResponse: string; // JSON response of brand CRM on status sync request
  conversionSyncResponse: string; // JSON response of brand CRM on conversion sync request
  transferResponse: string; // JSON response of brand CRM on send request
  transferRequest: { [key: string]: any }; // JSON request config to brand CRM on send request
  syncedAt: Date;
  isDeposited?: boolean;
  depositedAt?: Date;
  injectedAt: Date;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
}

export interface ILeadRegistrationModel extends ILeadRegistration {
  lead?: ILeadModel;
  company?: ICompanyModel;
  vertical?: IVerticalTemplateModel;
  affiliate?: IAffiliateModel;
  box?: IBox;
  broker?: IBrandModel;
  integration?: IBrandIntegrationModel;
  conversionHold?: IConversionHoldModel;
  changesHistory?: ILeadChangesHistoryModel[];
  brandStatusGroup?: IBrandStatusGroupModel;
}

export interface ILeadModel extends ILead {
  company?: ICompanyModel;
  vertical?: IVerticalTemplateModel;
  affiliate?: IAffiliateModel;
  registration?: ILeadRegistrationModel;
  registrations?: ILeadRegistrationModel[];
  sendingScenarios?: ISendingScenarioModel[];
  changesHistory?: ILeadChangesHistoryModel[];
}

export enum ELeadChangesHistoryCategory {
  Created = 0,
  Transferred = 1,
  BrandStatusChanged = 2,
  InternalStatusChanged = 3,
  Injected = 4,
  Converted = 5,
  AutologinUsed = 6,
  TestMode = 7,
  FraudCheck = 8,
  TransferAttempt = 9,
  InjectAttempt = 10
}

export interface ILeadChangesHistory {
  id: string;
  leadId: string;
  registrationId?: string;
  category: ELeadChangesHistoryCategory;
  previousValue: string;
  currentValue: string;
  producerId: string;
  producer?: {email: string}
  companyId: string;
  createdAt: Date;
}

export interface ILeadChangesHistoryModel extends ILeadChangesHistory {
  lead?: ILeadModel;
  registration?: ILeadRegistrationModel;
  producer?: IAccountModel;
  company?: ICompanyModel;
}

export interface ILeadInject {
  boxId: string,
  integrationId: string,
  redirect: {
    useRedirect: boolean,
    useProxy?: boolean
  },
  affiliate: {
    change: boolean,
    affiliateId: string | null
  },
  injectMode: string
  leadIds: string[]
}


export enum EInjectMode {
  Copy = 'copy',
  ChangeRegistration = 'change-reg',
  AddRegistration = 'add-reg',
}

export type ILeadRandomData = Pick<ILeadModel, 'funnel' | 'landingURL' | 'ip' | 'geo' | 'lang' | 'landingLang' | 'verticalTemplateId' | 'profile'>

export enum ELeadAutologinStatus {
  Pending = 'pending', // Autologin link is not activated
  Partial = 'partial', // Autologin link is executed but no info received
  Clear = 'clear', // Autologin link is activated without anomalies detected
  Suspicious = 'suspicious' // Autologin link is activated with anomalies detected
}

export interface ILeadAutologinInfo {
  autologinStatus: ELeadAutologinStatus;
  autologinPhasesMeta: ILeadAutologinPhasesMeta;
  autologinMatchMeta: ILeadAutologinMatchMeta | null;
  autologinDeviceMeta: ILeadAutologinDeviceMeta | null;
}

export interface ILeadAutologinMatchMeta {
  isIPMatched: boolean;
  isGEOMatched: boolean;
}

export interface ILeadAutologinPhasesMeta {
  isLinkActivated: boolean;
  isPageLoaded: boolean;
  isDeviceScanned: boolean;
  isLeadRedirected: boolean;
}

export interface ILeadAutologinDeviceMeta {
  scanType: string;
  ip: string; // IP of lead device which passed through autologin
  deviceId: string; // ID of lead device which passed through autologin
  webglHash?: boolean
  canvasHash?: boolean
  isDeviceIdUnique: boolean; // Is lead has unique device id
  userAgent: string; // UserAgent of lead device which passed through autologin
  country: string; // GEO code
  region?: string; // Region (State)
  city?: string; // City name
  isMobile?: boolean; // Is mobile device
  isBot?: boolean; // Is simulated device
  isVPN?: boolean; // Is VPN enabled
  isProxy?: boolean; // Is proxy enabled
  isTor?: boolean; // Is TOR enabled
  claimedOS?: string; // Claimed user device OS
  claimedBrowser?: string; // Claimed user browser
}
