import { Injectable } from '@angular/core'
import { map, Observable, combineLatest } from 'rxjs'
import { EAccountRoleType, IAccountModel } from '../../common/models/domain/models'
import { AccountService } from '../account/account.service'
import { AccountRole } from '../../common/models/ui/role.model'

@Injectable()
export class RoleService {

  constructor(
    private readonly accountService: AccountService
  ) {
  }

  private get account$(): Observable<Partial<IAccountModel>> {
    return this.accountService.account$ as Observable<Partial<IAccountModel>>
  }

  public get currentRole$(): Observable<EAccountRoleType> {
    return this.account$.pipe(
      map((a: Partial<IAccountModel>) => a?.currentCompany?.role as EAccountRoleType)
    )
  }

  public get isViewer$(): Observable<boolean> {
    return this.currentRole$.pipe(
      map((role: EAccountRoleType) => role == EAccountRoleType.TrackerViewer)
    )
  }

  public get isMaster$(): Observable<boolean> {
    return this.currentRole$.pipe(
      map((role: EAccountRoleType) => role == EAccountRoleType.TrackerMaster)
    )
  }

  public get isAdminOrViewer$(): Observable<boolean> {
    return combineLatest([
      this.isViewer$,
      this.isAdmin$
    ]).pipe(
      map(([isViewer, isAdmin]: [boolean, boolean]) => isViewer || isAdmin)
    )
  }

  public get isAdminOrMaster$(): Observable<boolean> {
    return combineLatest([
      this.isMaster$,
      this.isAdmin$
    ]).pipe(
      map(([isMaster, isAdmin]: [boolean, boolean]) => isMaster || isAdmin)
    )
  }

  public get isAdmin$(): Observable<boolean> {
    return this.currentRole$.pipe(
      map((role: EAccountRoleType) => {
        return role === EAccountRoleType.TrackerAdmin
          || role === EAccountRoleType.SystemAdmin
      })
    )
  }

  public get isSale$(): Observable<boolean> {
    return this.currentRole$.pipe(
      map((role: EAccountRoleType) => role === EAccountRoleType.TrackerSale)
    )
  }

  public can$(roles: AccountRole[]): Observable<boolean> {
    return this.currentRole$.pipe(map((currentRole: EAccountRoleType) => roles.includes(currentRole)))
  }

  public can(roles: AccountRole[]): boolean {
    if (!roles || roles && !roles.length) return true
    return roles.includes(this.accountService.getCurrentCompany()?.role as AccountRole)
  }

}
