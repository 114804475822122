import {Injectable} from '@angular/core';
import {DatePipe} from "@angular/common";
import {DateHelper} from "../../common/static/date.helper";

@Injectable({
  providedIn: 'root'
})
export class MapService {

  constructor(
    private datePipe: DatePipe
  ) {
  }

  public mapDate(obj: any): any {
    if (obj) {
      for (let key in obj) {
        if (key.slice(-2) === 'At') {
          obj[key] = this.transformDate(obj[key]);
        }
      }
    }
    return obj;
  }

  public transformDate(date: Date | undefined): string | null {
    if (date) {
      return this.datePipe.transform(date, DateHelper.fullFormat);
    }
    return null;
  }

  public mapFullname(obj: any): string | null {
    if (obj && obj.firstName && obj.lastName) {
      return `${obj.firstName} ${obj.lastName}`
    }
    return null;
  }

}
