import {
  RequestStatusMap,
  IIntegrationTemplateConfig,
  RequestConnectionOptions, RequestDataOptions
} from './system.model'
import { IBoxModel } from './box.model'
import { ILeadModel } from './lead.model'
import { IBrandModel } from './brand.model'
import { IVerticalTemplateModel } from './verticalTemplate.model'
import { ICompanyModel } from './company.model'
import { IBoxBrandModel } from './boxBrand.model'
import { KeyValue } from '@angular/common'

export interface IBrandIntegration {
  id: string;
  name: string;
  brokerId: string;
  code: string;
  isSendable: boolean;
  isSyncable: boolean;
  integrationTemplateId: string;
  description: string;
  connectionOptions?: RequestConnectionOptions | any;
  leadOptions?: RequestDataOptions;
  statusMap?: RequestStatusMap | KeyValue<string, string>[] | any;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  syncedAt?: Date; //feature
  verticalTemplateId?: string;
  isAutologinEnabled?: boolean
}

export interface IBrandIntegrationModel extends IBrandIntegration {
  company?: ICompanyModel;
  vertical?: IVerticalTemplateModel;
  attachment?: IBoxBrandModel;
  template?: IIntegrationTemplateConfig;
  broker?: IBrandModel;
  boxes?: IBoxModel[];
  leads?: ILeadModel[];
}

export enum EBrokerIntegrationHistoryEvent {
  PushData = 0,
  PullUpdates = 1,
  PullConversions = 2,
  Postback = 3,
  Auth = 4,
}

export interface IBrokerIntegrationRequestHistory {
  id: string;
  brokerId: string;
  integrationId: string;
  companyId: string;
  requestType: EBrokerIntegrationHistoryEvent;
  processingTimeMs: number;
  isSuccess: boolean;
  requestData: string; // json
  responseData: string; // json
  createdAt: Date,
}

export interface IBrokerIntegrationRequestHistoryModel extends IBrokerIntegrationRequestHistory {
  broker?: IBrandModel;
  brokerIntegration?: IBrandIntegrationModel;
  company?: ICompanyModel;
}
